import React from 'react'
import Nav from '../components/navbar';

function News() {
  return (
    <>
      <Nav></Nav>
      <div>under construction</div>
    </>
  );
}

export default News