import React from "react";
import "./style.css";

function Footer() {
  return (
    <div className="footer">
      <div className="footerinfo">
        <div className="footertext">
          <h1>Sustainable Priority</h1>
          <p>
            At Sustainable Priority, we are your trusted financial consulting
            partner committed to building a financially secure and sustainable
            future for your business. With a team of experienced professionals
            and innovative methods, we provide personalized solutions that align
            with your objectives and values. Our client-centric approach ensures
            measurable results, cost-effectiveness, and continuous improvement.
            Choose Sustainable Priority for expert financial guidance that
            drives growth and success while making a positive impact on the
            world. Let's shape a prosperous and sustainable tomorrow together.
          </p>
        </div>
        <div className="spacer"></div>
        <div className="footersocial">
          <h1>Get In Touch</h1>
          <p>Rua Trás Os Lagos, Nº 380 4525-325 Santa Maria da Feira</p>
          <p>Mobile: +351963206323/+351963206800</p>
          <p>geral@sustainablepriority.com</p>
        </div>
      </div>
      <div className="footerbrand">
        ©2023 Sustainable priority | Design by somebrand
      </div>
    </div>
  );
}

export default Footer