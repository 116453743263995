import React from 'react'
import Nav from '../components/navbar';
import "./style.css"
import Footer from '../components/footer/footer';

function Contactus() {
    
  return (
    <>
      <Nav></Nav>
      <div className="contactbackground"></div>
      <div className="contactbox">
        <form className="contactform">
          <label for="email">Email:</label>
          <br />
          <input
            type="email"
            id="email"
            name="email"
            placeholder="ex: 123@gmail.com"
          />
          <br />
          <label for="motivo">Reason:</label>
          <br />
          <textarea
            id="motivo"
            name="motivo"
            placeholder="state ur business"
            rows="5"
            cols="50"
          />
          <br />
          <input type="button" value="Disabled" />
          <br />
        </form>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Contactus