import { Route, Routes, BrowserRouter, Outlet } from "react-router-dom";
import News from "./pages/news";
import About from "./pages/about";
import Home from "./pages/home";
import Contactus from "./pages/contactus";

const Layout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="About" element={<About />} />
          <Route path="News" element={<News />} />
          <Route path="Contact" element={<Contactus />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
