import React from 'react'
import Nav from '../components/navbar';
import "./style.css";
import Footer from "../components/footer/footer";

function About() {
  return (
    <>
      <Nav></Nav>
      <div className="aboutbox">
        <div className="aboutcontainer">
          <div className="abouttext">
            <h1>About Us</h1>
            <p>
              Europe, Africa, South America and middle east are our main
              providers of financial needs. At sustainable priority,lda we’re
              dedicated to helping organizations solve their hardest challenges
              and realize their greatest ambitions and the work we do with our
              customers and partners as varied as they are.
            </p>
          </div>
          <div className="aboutimg"></div>
        </div>
        <div className="abouttext2">
          <p>
            Through our four service lines – Consulting, Business Strategy,
            Project Managment and Tax Law – we help our clients capitalize on
            transformative opportunities.
          </p>
          <p>
            We also help them fulfill regulatory requirements, keep investors
            informed and meet the demands and needs of all of their
            stakeholders.
          </p>
          <p>
            In a fast-changing world, we give them the support they need to be
            effective today and createlong-term value for tomorrow.{" "}
          </p>
          <p>
            Across all disciplines and from every angle, we draw on our shared
            creativity, experience, judgment and diverse perspectives to reframe
            the future for our clients – now, next and beyond.
          </p>
        </div>
      </div>
      <div className="aboutbox">
        <div className="aboutcontainer">
          <div className="aboutimg2"></div>
          <div className="abouttext3">
            <h1>Vision</h1>
            <p>
              The blend of technology and business innovation is revolutionizing
              how organizations operate, compete and deliver products and
              services.
            </p>
          </div>
        </div>
        <div className="abouttext2">
          <p>
            Through our strategic relationships with technology and industry
            leaders, and a host of business with specialized capabilities, we
            blend powerful technology, distinctive capabilities and industry
            experience anda experties to help our clients address their toughest
            challenges.
          </p>
          <b>
            We collaborate to create pioneering services, powered by leading and
            emerging technologies, including AI, blockchain, Internet of Things
            and cybersecurity.
          </b>
          <p>
            Our services address a broad range of client needs, including
            helping them turn insights into business breakthroughs, accelarating
            their supply chain strategies, and modernizing their finance and
            accounting processes.
          </p>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default About