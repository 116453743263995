import React from 'react'
import Nav from '../components/navbar';
import "./style.css"
import Footer from '../components/footer/footer';

function Home() {
    return (
      <>
        <Nav></Nav>
        <div className="services">
          <h1>our services</h1>
          <div className="servicecontainer">
            <div className="servicebox">
              <h2>1.Business trouble shooting</h2>
              <p>
                Business troubleshooting is the systematic process of
                identifying and addressing problems that hinder a business's
                success. It involves recognizing issues, gathering relevant
                data, and analyzing the root causes of the challenges faced. By
                implementing appropriate solutions, businesses can overcome
                obstacles and optimize their operations, ensuring continued
                growth and success. Troubleshooting is essential for maintaining
                a healthy and thriving business environment.
              </p>
            </div>
            <div className="servicebox">
              <h2>2.Financial advisement and projection</h2>
              <p>
                Financial advisement and projection are essential components of
                personal financial planning and investment management. They
                involve analyzing an individual's financial situation, setting
                financial goals, and creating a strategic plan to achieve those
                objectives.
              </p>
            </div>
            <div className="servicebox">
              <h2>3.Financial analysis</h2>
              <p>
                Financial analysis is the process of evaluating the financial
                health and performance of a business, organization, or
                individual. It involves examining financial statements, ratios,
                and other financial data to gain insights into the entity's
                financial position and make informed decisions. Financial
                analysis plays a crucial role in various contexts, including
                business management, investment decisions, and credit
                assessments.
              </p>
            </div>
          </div>
          <div className="servicecontainer">
            <div className="servicebox">
              <h2>4.Financial consulting</h2>
              <p>
                Financial consulting is a professional service provided by
                financial experts or consulting firms to help individuals,
                businesses, or organizations manage their financial affairs
                effectively and make informed financial decisions. Financial
                consultants offer specialized expertise and strategic advice on
                various financial matters, ranging from personal finance to
                corporate finance and investment management. Their primary goal
                is to help clients achieve their financial objectives, optimize
                financial performance, and mitigate financial risks.
              </p>
            </div>
            <div className="servicebox">
              <h2>5.Strategy implementation to financial requirements</h2>
              <p>
                Strategy implementation to financial requirements involves
                putting into action a well-defined strategy that aligns with
                specific financial objectives. It encompasses allocating the
                necessary financial resources, budgets, and investments to
                support the strategy's execution. Regular monitoring,
                adjustments, and accountability are crucial to ensure progress
                toward achieving financial targets and optimizing overall
                financial performance. By effectively implementing the strategy,
                an organization can enhance its financial position and work
                towards sustainable growth and success.
              </p>
            </div>
            <div className="servicebox">
              <h2>6.Financial procurement</h2>
              <p>
                Financial procurement for investment and financing operations
                involves identifying the financial needs of a project or
                initiative and selecting suitable funding sources to secure the
                required capital. This process is crucial for efficiently
                managing the financial aspects of investments, ensuring the
                availability of necessary funds, and optimizing the returns on
                those investments. By carefully procuring the right financial
                resources, organizations can effectively implement their growth
                strategies and achieve their financial objectives.
              </p>
            </div>
          </div>
          <p>
            Our connection with the financial intituions ables us to source the
            best solution for the present needs of our clients.
          </p>
        </div>
        <div className="sucessstorie">
          <div className="sucesstext">
            <h3>Success Stories</h3>
            <p>
              I want to share my success story with Sustainable Priority, the
              exceptional financial consulting service that has made a profound
              impact on my financial journey. From the moment I connected with
              their team, I knew I had found the right partner to help me
              achieve my goals. Their personalized approach and expertise helped
              me develop a comprehensive financial plan tailored to my specific
              needs. With their ongoing support and monitoring, I made informed
              decisions and saw my savings and investments grow. What truly sets
              Sustainable Priority apart is their unwavering commitment to my
              success. Their dedication to my financial well-being was evident
              in every interaction. Thanks to Sustainable Priority, my financial
              journey took a remarkable turn. I witnessed my savings grow, my
              investments thrive, and my overall financial health improve
              significantly. I am immensely grateful for the partnership I have
              with Sustainable Priority. I highly recommend their services to
              anyone seeking reliable and trustworthy financial advice. With
              Sustainable Priority by your side, you can confidently navigate
              the financial landscape and achieve your dreams. I want to share
              my success story with Sustainable Priority, the exceptional
              financial consulting service that has made a profound impact on my
              financial journey. From the moment I connected with their team, I
              knew I had found the right partner to help me achieve my goals.
              Their personalized approach and expertise helped me develop a
              comprehensive financial plan tailored to my specific needs. With
              their ongoing support and monitoring, I made informed decisions
              and saw my savings and investments grow. What truly sets
              Sustainable Priority apart is their unwavering commitment to my
              success. Their dedication to my financial well-being was evident
              in every interaction. Thanks to Sustainable Priority, my financial
              journey took a remarkable turn. I witnessed my savings grow, my
              investments thrive, and my overall financial health improve
              significantly. I am immensely grateful for the partnership I have
              with Sustainable Priority. I highly recommend their services to
              anyone seeking reliable and trustworthy financial advice. With
              Sustainable Priority by your side, you can confidently navigate
              the financial landscape and achieve your dreams. We thrive on
              innovation, new approaches and cutting-edge techniques to stay
              ahead of the curve. Our dedication to leveraging the latest
              technologies and best practices ensures that we deliver
              forward-thinking solutions that meet the demands of today's
              dynamic business.
            </p>
            <h3>Carlos Moreira, Metalwork Soluction Lda</h3>
          </div>
        </div>
        <div className="whyus">
          <h1>Why Choose Us</h1>
          <div className="whyusholder">
            <div className="whyusbox">
              <h2>Extensive Experience</h2>
              <p>
                Our team boasts a wealth of experience in the industry, with
                years of proven success in delivering exceptional results. We
                have encountered and overcome diverse challenges, honing our
                skills and expertise to better serve our clients.
              </p>
            </div>
            <div className="whyusbox">
              <h2>Client-Centric Approach</h2>
              <p>
                At the core of our business philosophy is a deep commitment to
                our clients' success. We take the time to understand your unique
                needs and tailor our services to provide personalized solutions
                that drive tangible outcomes.
              </p>
            </div>
            <div className="whyusbox">
              <h2>Innovative Methods</h2>
              <p>
                We thrive on innovation, new approaches and cutting-edge
                techniques to stay ahead of the curve. Our dedication to
                leveraging the latest technologies and best practices ensures
                that we deliver forward-thinking solutions that meet the demands
                of today's dynamic business.
              </p>
            </div>
          </div>
          <div className="whyusholder">
            <div className="whyusbox">
              <h2>Results-Driven Strategies</h2>
              <p>
                Our focus is on delivering measurable results. We don't believe
                in one-size-fits-all solutions; instead, we develop customized
                strategies that align with your objectives and drive real,
                sustainable outcomes.
              </p>
            </div>
            <div className="whyusbox">
              <h2>Collaborative Partnership</h2>
              <p>
                When you choose us, you gain a trusted partner invested in your
                success. We believe in open communication, transparency, and
                fostering a collaborative relationship that empowers you to make
                informed decisions.
              </p>
            </div>
            <div className="whyusbox">
              <h2>Proven Track Record</h2>
              <p>
                Our past successes speak for themselves. We have a proven track
                record of helping clients achieve their goals and surpass
                expectations. Your business is in capable hands with a team that
                knows how to deliver.
              </p>
            </div>
          </div>
          <div className="whyusholder">
            <div className="whyusbox">
              <h2>Continuous Improvement</h2>
              <p>
                We believe in continuous improvement, always seeking ways to
                enhance our services and refine our methods. As industry trends
                evolve, we adapt our strategies to ensure you remain at the
                forefront of your market.
              </p>
            </div>
            <div className="whyusbox">
              <h2>Cost-Effective Solutions</h2>
              <p>
                Our innovative methods not only yield superior results but also
                ensure cost-effectiveness. We maximize the value you receive,
                providing a strong return on your investment.
              </p>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </>
    );
}

export default Home