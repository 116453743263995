import { Link } from "react-router-dom";
import "./style.css"

function Nav() {

       

  if (window.location.pathname === "/") {
    return (
      <>
        <div className="homenav">
          <div className="navitems">
            <li className="branditem">
              <Link to="/">SUSTAINABLE PRIORITY</Link>
            </li>
            <li>
              <Link to="/About">ABOUT US</Link>
            </li>
            <li>
              <Link to="/News">NEWS</Link>
            </li>
            <li>
              <Link to="/Contact">CONTACT US</Link>
            </li>
          </div>
          <div className="homenavtext">
            <h3>Your Bright Future</h3>
            <h1>OUR MISSION</h1>
            <p>
              Sustainable Priority is a leading financial advisory firm
              dedicated to promoting and implementing sustainable practices in
              the economic sector. Our mission is to help companies,
              organizations and individuals achieve financial success through
              environmentally responsible approaches, thus contributing to
              building a more sustainable future. We believe that financial
              sustainability is essential to ensuring long-term prosperity for
              both our customers and the planet. Our team of highly qualified
              experts works closely with each client to develop financial
              strategies that consider the environmental, social and
              governmental impact of their decisions.
            </p>
            <button>Read more</button>
          </div>
        </div>
      </>
    );
  }
  else{  return (
       <>
        <div className="othernav">
          <div className="navitems">
            <li className="branditem">
              <Link to="/">SUSTAINABLE PRIORITY</Link>
            </li>
            <li>
              <Link to="/About">ABOUT US</Link>
            </li>
            <li>
              <Link to="/News">NEWS</Link>
            </li>
            <li>
              <Link to="/Contact">CONTACT US</Link>
            </li>
        </div>
      </div>
      </>
        )}
    
}
export default Nav;
